import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs, limit } from "firebase/firestore";
import { useParams, Link } from "react-router-dom";
import db from "../Firebase";
import {
  Container,
  Row,
  Col,
  Carousel,
  Tab,
  Nav,
  Table,
  Spinner,
} from "react-bootstrap";
import facebook from "../assets/facebook.png";
import snapchat from "../assets/snapchat.png";
import email from "../assets/email.png";
import twitter from "../assets/twitter.png";
import GoogleAd from "../googleads";
import { Img } from "react-image";

const DetailPage = () => {
  const { id } = useParams();
  console.log(id);
  const [productData, setProductData] = useState([]);
  const [proList, setProList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [key, setKey] = useState("specification");
  const [sortData, setSortData] = useState({});

  const ActiveStyle = {
    textAlign: "center",
    background: "white",
    fontSize: "16px",
    borderBottom: "0.2rem solid #B71C1C",
    borderBottomColor: "#B71C1C ",
    color: "black",
  };
  const inActiveStyle = {
    ...ActiveStyle,
    background: "transparent",
    borderBottom: "none",
  };
  function openInNewTab(url) {
    const url1 = url.split("@l ");
    console.log(url1);
    var win = window.open(url1[1], "_blank");
    win.focus();
  }

  const getData = async () => {
    const q = query(collection(db, "Product"), where("productId", "==", id));
    const item = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      item.push(doc.data());

      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
    });
    setProductData(item);
    const object = [...item];
    console.log(object);
    getProList(object[0].brandName);
    const objCopy = object[0];
    console.log(objCopy);

    const {
      productName,
      productId,
      images,
      description,
      releasedDate,
      createdDate,
      brandName,
      categoryName,
      ...filtered
    } = objCopy;
    console.log(filtered);

    // const sortedList = Object.entries(object[0]).filter(
    //   ([data1]) => !contentIds.some((data2) => data2 === data1)
    // );
    // console.log(sortedList);
    setSortData(filtered);
    console.log(sortData);
  };

  // const getBrandData = () => {
  //   fetch(url, { method: "GET" })
  //     .then((response) => response.json())
  //     .then((json) => {
  //       setBrandList(json);
  //       console.log(json);
  //       setIsLoading(false);
  //     })
  //     .catch((error) => console.log(error));
  // };

  const getProList = async (brandname) => {
    const proRef = collection(db, "Product");
    const q2 = query(proRef, where("brandName", "==", brandname), limit(4));
    const itemsData = [];
    const querySnapshot = await getDocs(q2);
    querySnapshot.forEach((doc) => {
      if (doc.data().productId !== id) {
        itemsData.push(doc.data());
      }
      console.log(`${doc.id} => ${doc.data().productName}`);
    });
    setProList(itemsData);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
    // getBrandData();
    // getProList();
    console.log(sortData);
    console.log(`data ${productData}`);
  }, []);

  return (
    <div>
      {/* <GoogleAd slot={process.env.REACT_APP_AD_SLOT_PH} /> */}
      <div style={{ height: "1.5rem" }}></div>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={7} lg={8} xl={9}>
            {isLoading ? (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              productData.map((pro) => (
                <div>
                  <h2 className="font-oswald1">{pro.productName}</h2>
                  <p>
                    {pro.brandName}, {pro.categoryName}
                  </p>
                  <div
                    className="col"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "30px",
                      width: "140px",
                    }}
                  >
                    <img src={twitter} alt="twitter" height={30} />
                    <img src={facebook} alt="facebook" height={30} />
                    <img src={email} alt="email" height={30} />
                    <img src={snapchat} alt="snapchat" height={30} />
                  </div>
                  <div style={{ height: "1.5rem" }}></div>
                  <div>
                    <Carousel variant="dark" indicators={false} interval={null}>
                      {pro.images.map((image) => (
                        <Carousel.Item>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              float: "none",
                              margin: "0 auto",
                              width: "90%",
                              height: "400px",
                            }}
                          >
                            <Img
                              src={image}
                              alt={pro.productName}
                              width="90%"
                              height="400px"
                              style={{
                                objectFit: "contain",
                              }}
                              loader={
                                <div
                                  style={{
                                    display: "flex",
                                    position: "sticky",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    float: "none",
                                    margin: "0 auto",
                                    width: "90%",
                                    height: "400px",
                                  }}
                                >
                                  <Spinner
                                    animation="border"
                                    variant="secondary"
                                    size="sm"
                                  />
                                </div>
                              }
                            />
                          </div>

                          {/* <div
                            style={{
                              backgroundImage: `url(${image})`,
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: "contain",
                              width: "60%",
                              height: "400px",
                              float: "none",
                              margin: "0 auto",
                            }}
                          ></div> */}
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                  <div style={{ height: "1.5rem" }}></div>
                  <Tab.Container
                    activeKey={key}
                    onSelect={(key) => setKey(key)}
                  >
                    <Col>
                      <Row>
                        <Nav
                          className="flex-row"
                          style={{
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Nav.Link
                              eventKey="specification"
                              className="font-oswald flex-row"
                              style={
                                key === "specification"
                                  ? ActiveStyle
                                  : inActiveStyle
                              }
                            >
                              Specification
                            </Nav.Link>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Nav.Link
                              eventKey="review"
                              className="font-oswald flex-row"
                              style={
                                key === "review" ? ActiveStyle : inActiveStyle
                              }
                            >
                              <span>Review</span>
                            </Nav.Link>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Nav.Link
                              eventKey="pictures"
                              className="font-oswald flex-row"
                              style={
                                key === "pictures" ? ActiveStyle : inActiveStyle
                              }
                            >
                              Pictures
                            </Nav.Link>
                          </Col>
                          <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                            <Nav.Link
                              eventKey="prices"
                              className="font-oswald flex-row"
                              style={
                                key === "prices" ? ActiveStyle : inActiveStyle
                              }
                            >
                              Prices
                            </Nav.Link>
                          </Col>
                        </Nav>
                      </Row>
                      <Row>
                        <Tab.Content>
                          <Tab.Pane eventKey="specification">
                            <div style={{ height: "1.5rem" }}></div>{" "}
                            <Table borderless="true" responsive="sm">
                              <tbody>
                                {Object.entries(sortData).map(([k, v]) => (
                                  <tr key={k}>
                                    <td
                                      style={{
                                        padding: "0",
                                        paddingRight: "3px",
                                        color: "#B71C1C ",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {k === "communication"
                                        ? k
                                            .split("unication")
                                            .join("S")
                                            .toUpperCase()
                                        : k.toUpperCase()}
                                    </td>
                                    {Object.entries(v[0]).map(([k1, v1]) => (
                                      <tr key={k}>
                                        <td
                                          style={{
                                            width: "130px",
                                            padding: "0",
                                          }}
                                        >
                                          {v1 === ""
                                            ? ""
                                            : k1.charAt(0).toUpperCase() +
                                              k1.slice(1).toString()}
                                        </td>
                                        <td>{v1 === "" ? "" : v1}</td>
                                      </tr>
                                    ))}
                                    <tr>
                                      <td
                                        style={{
                                          height: "8px",
                                        }}
                                      ></td>
                                    </tr>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                            {/* {proData
                            .map((food, index) =>
                              Object.keys(food)
                                .map((key) => (
                                  <div></div>
                                ))
                                .join("\n")
                            )
                            .join("\n")} */}
                            {/* {proData.map((i) =>
                            Object.entries(i).map(
                              ([k, index]) => `${k} :${i[k].toString()}`
                            )
                          )} */}
                          </Tab.Pane>
                          <Tab.Pane
                            eventKey="review"
                            className="font-tinos"
                            style={{ fontSize: "20px" }}
                          >
                            <div style={{ height: "1.5rem" }}></div>
                            {pro.description[0].shortDescription}
                            <div style={{ height: "20px" }}></div>
                            {pro.description[0].longDescription
                              .split("@p ")
                              .map(function (desc, index) {
                                return (
                                  <>
                                    {desc.startsWith("https") ? (
                                      <img
                                        src={desc}
                                        alt="descriptionImage"
                                        width="100%"
                                        height="400"
                                        style={{
                                          display: "flex",
                                          float: "none",
                                          margin: "0 auto",
                                          objectFit: "contain",
                                          justifyContent: "center",
                                        }}
                                      />
                                    ) : desc.startsWith("@l ") ? (
                                      <p
                                        onClick={() => openInNewTab(desc)}
                                        style={{
                                          textDecorationLine: "underline",
                                          color: "blue",
                                        }}
                                      >
                                        Via
                                      </p>
                                    ) : (
                                      // desc
                                      //   .split("@l ")
                                      //   .map(function (data1, index1) {
                                      //     return (
                                      //       <a
                                      //         href={data1}
                                      //         target="_blank"
                                      //         rel="noreferrer noopener"
                                      //       >
                                      //         Via
                                      //       </a>
                                      //     );
                                      //   })
                                      <p key={index}>{desc}</p>
                                    )}
                                    <div style={{ height: "10px" }}></div>
                                    {/* {desc.startsWith("https") ? (
                                      <img
                                        src={desc}
                                        alt="image"
                                        width="100%"
                                        height="400"
                                        style={{
                                          display: "flex",
                                          float: "none",
                                          margin: "0 auto",
                                          objectFit: "contain",
                                          justifyContent: "center",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )} */}
                                  </>
                                );
                              })}
                          </Tab.Pane>
                          <Tab.Pane eventKey="pictures">
                            <div style={{ height: "1.5rem" }}></div>
                            <h5 className="font-tinos">
                              {pro.productName} official images
                            </h5>
                            <div style={{ height: "1.5rem" }}></div>
                            {pro.images.map((item) => (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    float: "none",
                                    margin: "0 auto",
                                    width: "90%",
                                    height: "400px",
                                  }}
                                >
                                  <Img
                                    src={item}
                                    alt={pro.productName}
                                    width="80%"
                                    height="400px"
                                    style={{
                                      objectFit: "contain",
                                    }}
                                    loader={
                                      <div
                                        style={{
                                          display: "flex",
                                          position: "sticky",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          float: "none",
                                          margin: "0 auto",
                                          width: "60%",
                                          height: "400px",
                                        }}
                                      >
                                        <Spinner
                                          animation="border"
                                          variant="secondary"
                                          size="sm"
                                        />
                                      </div>
                                    }
                                  />
                                </div>

                                {/* <div
                                  style={{
                                    backgroundImage: `url(${item})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    backgroundSize: "contain",
                                    width: "60%",
                                    height: "400px",
                                    float: "none",
                                    margin: "0 auto",
                                  }}
                                ></div> */}
                                <div style={{ height: "1.5rem" }}></div>
                              </>
                            ))}
                          </Tab.Pane>
                          <Tab.Pane eventKey="prices">
                            <div style={{ height: "1.5rem" }}></div>
                            {/* <ul>
                              {Object.entries(sortData).map(([key, val]) => (
                                <li key={key}>
                                  <strong>{key}</strong>
                                  {
                                    <ul>
                                      {Object.entries(val[0]).map(
                                        ([key1, val1]) => (
                                          <li key={key}>
                                            <strong>{key1}</strong>: {val1}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  }
                                </li>
                              ))}
                            </ul> */}
                            {/* <ul>
                              {Object.entries(productData[0]).map(
                                ([key, val]) => (
                                  <li key={key}>
                                    <strong>
                                      {key.charAt(0).toUpperCase() +
                                        key.slice(1)}
                                    </strong>{" "}
                                    {
                                      <ul>
                                        {Object.entries(val[0]).map(
                                          ([key1, val1]) => (
                                            <li key={key}>
                                              <strong>{key1}</strong>:{" "}
                                              {val1.toString()}
                                            </li>
                                          )
                                        )}
                                      </ul>
                                    }
                                  </li>
                                )
                              )}
                            </ul> */}
                            {/* <Table striped bordered hover>
                            <tbody>
                              {newData.map((product) => (
                                <tr key={product._id}>
                                  <td>{product.battery.name}</td>
                                  <tr>
                                    <td style={{ padding: "2px 10px" }}>
                                      {product.battery.batteryType.name}
                                    </td>
                                    <td>{product.battery.batteryType.data}</td>
                                  </tr>
                                  <tr>
                                    <td style={{ padding: "2px 10px" }}>
                                      {product.battery.charging.name}
                                    </td>
                                    <td>{product.battery.charging.data}</td>
                                  </tr>
                                </tr>
                              ))}
                            </tbody>
                          </Table> */}

                            {/* <tbody>
                            {proData.map((d) =>
                              Object.entries(d).map((error_code, i) => {
                                return Object.entries(d[error_code]).map(
                                  (error_description, index) =>
                                    index === 0 ? (
                                      <tr>
                                        <td
                                          rowSpan={d[error_code].length}
                                          key={i}
                                        >
                                          {error_code}
                                        </td>
                                        <td key={index} rowSpan="1">
                                          {error_description}
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr>
                                        <td key={index} rowSpan="1">
                                          {error_description}
                                        </td>
                                      </tr>
                                    )
                                );
                              })
                            )}
                          </tbody> */}
                          </Tab.Pane>
                        </Tab.Content>
                      </Row>
                    </Col>
                  </Tab.Container>
                  <div style={{ height: "1.5rem" }}></div>
                  <div>
                    <h4>RECOMMENDED</h4>
                    <div style={{ borderBottom: "3px solid black" }}></div>
                    <Container>
                      <Row>
                        {proList.map((product) => (
                          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <div style={{ height: "1.5rem" }}></div>
                            <div key={product.productId}>
                              <Link
                                onClick={() => {
                                  window.location.href = `/detail/${product.productId}`;
                                }}
                              >
                                <div
                                  style={{
                                    borderRadius: "16px",
                                    // backgroundImage: `url(${product.images[0]})`,
                                    // backgroundRepeat: "no-repeat",
                                    // backgroundPosition: "center",
                                    // backgroundSize: "contain",
                                    width: "90%",
                                    height: "270px",
                                    display: "flex",

                                    justifyContent: "center",
                                    alignItems: "center",
                                    float: "none",
                                    padding: "0",
                                    margin: "0 auto",
                                    backgroundColor: "#F5F5F5",
                                  }}
                                >
                                  <Img
                                    src={product.images[0]}
                                    alt={product.productName}
                                    width="80%"
                                    height="250px"
                                    style={{
                                      objectFit: "contain",
                                    }}
                                    loader={
                                      <div
                                        style={{
                                          display: "flex",
                                          position: "sticky",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          float: "none",
                                          margin: "0 auto",
                                          width: "60%",
                                          height: "250px",
                                        }}
                                      >
                                        <Spinner
                                          animation="border"
                                          variant="secondary"
                                          size="sm"
                                        />
                                      </div>
                                    }
                                  />
                                </div>
                              </Link>

                              <div style={{ height: "0.6rem" }}></div>
                              <p
                                className="font-oswald1"
                                style={{
                                  fontSize: "18px",
                                  paddingLeft: "25px",
                                  paddingRight: "25px",
                                }}
                              >
                                {product.productName}
                              </p>
                            </div>
                          </Col>
                        ))}

                        {/* <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          xs=6
                        </Col> */}
                      </Row>
                    </Container>
                  </div>
                </div>
              ))
            )}
          </Col>
          <Col xs={12} sm={12} md={5} lg={4} xl={3}>
            <GoogleAd slot={process.env.REACT_APP_AD_SLOT_PV} />
            {/* <div
              className="font-oswald"
              style={{ display: "block", textAlign: "center" }}
            >
              <h4
                className="menuText"
                style={{
                  height: "2.5rem",
                  backgroundColor: "darkgrey",
                  color: "white",
                }}
              >
                Technology
              </h4>
              <Row xs={3}>
                {isLoading === false &&
                  brandList.map((brand) => (
                    <div key={brand._id}>
                      <button
                        className="secondary"
                        style={{
                          width: "6rem",
                          border: "solid white",
                          height: "2.5rem",
                          background: "white",
                        }}
                      >
                        {brand.name}
                      </button>
                    </div>
                  ))}
              </Row>
            </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DetailPage;
