import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import db from "../Firebase";
import { Container, Row, Col, Spinner, Toast } from "react-bootstrap";
import GoogleAd from "../googleads";
import { Link, useParams } from "react-router-dom";
import { Img } from "react-image";

const FilterDataPage = () => {
  const { name } = useParams();
  const [proList, setProList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const filterData = [];
  const getFilterList = async () => {
    const querySnapshot1 = await getDocs(collection(db, "Categories"));
    const cats = [];
    querySnapshot1.forEach((doc1) => {
      cats.push(doc1.data());
    });
    const object1 = [...cats];
    // console.log(object1);

    const data = object1.map(function (cValue, idx) {
      filterData.push(cValue.catName);

      // console.log(cValue.catName);
    });
    // console.log(data1);
    // console.log(data1.includes(name));
    getCatData();
  };

  const getCatData = async () => {
    console.log(filterData.includes(name));
    const q1 = query(
      collection(db, "Product"),
      filterData.includes(name)
        ? where("categoryName", "==", name)
        : where("brandName", "==", name)
    );
    const itemsData = [];
    const querySnapshot = await getDocs(q1);
    querySnapshot.forEach((doc) => {
      itemsData.push(doc.data());
      // console.log(`${doc.id} => ${doc.data()}`);
    });
    setProList(itemsData);
    // console.log(proList);
    setIsLoading(false);
  };

  useEffect(() => {
    getFilterList();
    // getCatData();
  }, []);

  return (
    <div>
      {/* <GoogleAd slot={process.env.REACT_APP_AD_SLOT_HH} /> */}
      <div style={{ height: "1.5rem" }}></div>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={7} lg={8} xl={9}>
            {isLoading ? (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : proList.length === 0 ? (
              <Toast>
                <Toast.Body>No data</Toast.Body>
              </Toast>
            ) : (
              proList.map((pro) => (
                <div key={pro.productId}>
                  <h2 className="font-oswald1">{pro.productName}</h2>
                  <p>
                    {pro.brandName}, {pro.categoryName}
                  </p>
                  <div style={{ height: "1.5rem" }}></div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      float: "none",
                      margin: "0 auto",
                      width: "90%",
                      height: "400px",
                    }}
                  >
                    <Img
                      src={pro.images[0]}
                      alt={pro.productName}
                      width="90%"
                      height="400px"
                      style={{
                        objectFit: "contain",
                      }}
                      loader={
                        <div
                          style={{
                            display: "flex",
                            position: "fixed",
                            justifyContent: "center",
                            alignItems: "center",
                            float: "none",
                            margin: "0 auto",
                            width: "90%",
                            height: "400px",
                          }}
                        >
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        </div>
                      }
                    />
                  </div>
                  <div
                    style={{
                      height: "1rem",
                    }}
                  ></div>
                  <div className=" font-tinos text">
                    <h6 style={{ fontSize: "20px" }}>
                      {pro.description[0].shortDescription}
                    </h6>
                  </div>
                  <div style={{ height: "0.2rem" }}></div>
                  <Link to={`/detail/${pro.productId}`}>
                    {" "}
                    <button
                      className="font-oswald"
                      style={{
                        border: "solid black",
                        color: "white",
                        backgroundColor: "black",
                        fontWeight: "500",
                        borderRadius: "0.25rem",
                        fontSize: "15px",
                      }}
                    >
                      Read More
                    </button>
                  </Link>
                  <div style={{ height: "2rem" }}></div>
                </div>
              ))
            )}
          </Col>
          <Col xs={12} sm={12} md={5} lg={4} xl={3}>
            <GoogleAd slot={process.env.REACT_APP_AD_SLOT_HV} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FilterDataPage;
