import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  startAt,
} from "firebase/firestore";
import db from "../Firebase";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import GoogleAd from "../googleads";
import { Link } from "react-router-dom";
import { Img } from "react-image";

const HomePage = () => {
  const [proList, setProList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  const getProData = async () => {
    const proRef = collection(db, "Product");
    const q = query(proRef, orderBy("createdDate", "desc"));
    const querySnapshot = await getDocs(q);
    const item = [];
    querySnapshot.forEach((doc) => {
      item.push(doc.data());
      console.log(`${doc.id} => ${doc.data()}`);
    });
    setProList(item);
  };

  const getNewsList = async () => {
    const newsRef = collection(db, "News");
    const q = query(newsRef, orderBy("releasedDate", "desc"));
    const querySnapshot = await getDocs(q);
    const item = [];
    querySnapshot.forEach((doc) => {
      item.push(doc.data());
      console.log(`${doc.id} => ${doc.data()}`);
    });
    setNewsList(item);
    setIsLoading(false);
  };

  useEffect(() => {
    getProData();
    getNewsList();
    console.log(proList);
  }, []);

  return (
    <div>
      {/* <GoogleAd slot={process.env.REACT_APP_AD_SLOT_HH} /> */}
      <div style={{ height: "1.5rem" }}></div>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={7} lg={8} xl={9}>
            {isLoading === false &&
              newsList.map((news) => (
                <Row key={news.newsId}>
                  <Col xs={12} sm={12} md="auto" lg={5} xl={5}>
                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                        // backgroundColor: "grey",
                        borderRadius: "15px",
                        margin: "10px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        float: "none",
                        padding: "0",
                      }}
                    >
                      <Link to={`/news-detail/${news.newsId}`}>
                        <Img
                          src={news.profileImage}
                          alt="newsImage"
                          width="100%"
                          height="auto"
                          style={{
                            objectFit: "contain",
                            borderRadius: "10px",
                          }}
                          loader={
                            <div
                              style={{
                                display: "flex",
                                position: "sticky",
                                justifyContent: "center",
                                alignItems: "center",
                                float: "none",
                                margin: "0 auto",
                                width: "100%",
                                height: "170px",
                              }}
                            >
                              <Spinner
                                animation="border"
                                variant="secondary"
                                size="sm"
                              />
                            </div>
                          }
                        />
                      </Link>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md="auto" lg={7} xl={7}>
                    <div
                      className="font-oswald"
                      style={{
                        // justifyContent: "flex-end",
                        // alignItems: "flex-end",
                        width: "100%",
                        height: "auto",
                        // position: "relative",
                        paddingTop: "15px",
                      }}
                    >
                      <Link
                        to={`/news-detail/${news.newsId}`}
                        style={{
                          textDecorationLine: "none",
                          color: "black",
                        }}
                      >
                        <h4>{news.title}</h4>
                      </Link>

                      <p>{formatDate(new Date(news.releasedDate.toDate()))}</p>
                    </div>
                    <div style={{ height: "1rem" }}></div>
                  </Col>
                </Row>
              ))}
          </Col>
          <Col xs={12} sm={12} md={5} lg={4} xl={3}>
            <GoogleAd slot={process.env.REACT_APP_AD_SLOT_HV} />
            <div>
              <h4>LATEST DEVICES</h4>
              <div style={{ borderBottom: "3px solid black" }}></div>
              <div style={{ height: "1.5rem" }}></div>
              {proList.map((pro) => (
                <div
                  className="font-oswald"
                  style={{
                    width: "100%",
                    height: "auto",
                    // backgroundColor: "grey",
                    borderRadius: "15px",
                    margin: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    float: "none",
                    padding: "0",
                  }}
                >
                  <Link
                    to={`/detail/${pro.productId}`}
                    style={{ textDecorationLine: "none", color: "black" }}
                  >
                    <Img
                      src={pro.images[0]}
                      alt={pro.productName}
                      width="100%"
                      height="200px"
                      style={{
                        objectFit: "contain",
                        borderRadius: "10px",
                      }}
                      loader={
                        <div
                          style={{
                            display: "flex",
                            position: "sticky",
                            justifyContent: "center",
                            alignItems: "center",
                            float: "none",
                            margin: "0 auto",
                            width: "100%",
                            height: "170px",
                          }}
                        >
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        </div>
                      }
                    />

                    <h5>{pro.productName}</h5>

                    {/* <p className="font-tinos">
                      {formatDate(new Date(news.releasedDate.toDate()))}
                    </p> */}
                  </Link>
                  <div style={{ height: "1rem" }}></div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HomePage;
