import React, { Component } from "react";
import PropTypes from "prop-types";

const googleAdId = process.env.REACT_APP_DATA_AD_CLIENT;

class GoogleAd extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
  //   googleInit = null;

  //   componentDidMount() {
  //     const { timeout } = this.props;
  //     this.googleInit = setTimeout(() => {
  //       if (typeof window !== "undefined")
  //         (
  //             window.adsbygoogle = window.adsbygoogle || []).push({})
  //             ;
  //     }, timeout);
  //   }

  //   componentWillUnmount() {
  //     if (this.googleInit) clearTimeout(this.googleInit);
  //   }

  render() {
    const { slot } = this.props;
    return (
      <div>
        <ins
          className="adsbygoogle"
          style={{ display: "block" }}
          data-ad-client={googleAdId}
          data-ad-slot={slot}
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>
    );
  }
}

GoogleAd.propTypes = {
  slot: PropTypes.string,
  timeout: PropTypes.number,
};

GoogleAd.defaultProps = {
  timeout: 200,
};

export default GoogleAd;
