import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import GoogleAd from "../googleads";
import { Img } from "react-image";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useParams, Link } from "react-router-dom";
import db from "../Firebase";
import facebook from "../assets/facebook.png";
import snapchat from "../assets/snapchat.png";
import email from "../assets/email.png";
import twitter from "../assets/twitter.png";

const NewsDetailPage = () => {
  const { id } = useParams();
  const [newsData, setNewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  function openInNewTab(url) {
    const url1 = url.split("@l ");
    console.log(url1);
    var win = window.open(url1[1], "_blank");
    win.focus();
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  const getNewsData = async () => {
    const q = query(collection(db, "News"), where("newsId", "==", id));
    const item = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      item.push(doc.data());

      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
    });
    setNewsData(item);
    setIsLoading(false);
  };

  useEffect(() => {
    getNewsData();
  }, []);

  return (
    <div>
      {/* <GoogleAd slot={process.env.REACT_APP_AD_SLOT_PH} /> */}
      <div style={{ height: "1.5rem" }}></div>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={7} lg={8} xl={9}>
            {isLoading ? (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Spinner animation="border" variant="secondary" />
              </div>
            ) : (
              newsData.map((news) => (
                <div key={news.newsId}>
                  <h2 className="font-oswald1">{news.title}</h2>
                  <p>{formatDate(new Date(news.releasedDate.toDate()))}</p>
                  <div style={{ height: "1.5rem" }}></div>
                  <div
                    className="col"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "30px",
                      width: "140px",
                    }}
                  >
                    <img src={twitter} alt="twitter" height={30} />
                    <img src={facebook} alt="facebook" height={30} />
                    <img src={email} alt="email" height={30} />
                    <img src={snapchat} alt="snapchat" height={30} />
                  </div>
                  <div style={{ height: "1.5rem" }}></div>
                  <div>
                    <Img
                      src={news.profileImage}
                      alt="newsImage"
                      width="90%"
                      height="auto"
                      style={{
                        objectFit: "contain",
                      }}
                      loader={
                        <div
                          style={{
                            display: "flex",
                            position: "sticky",
                            justifyContent: "center",
                            alignItems: "center",
                            float: "none",
                            margin: "0 auto",
                            width: "90%",
                            height: "400px",
                          }}
                        >
                          <Spinner
                            animation="border"
                            variant="secondary"
                            size="sm"
                          />
                        </div>
                      }
                    />
                  </div>
                  <div style={{ height: "1.5rem" }}></div>
                  <div className="font-tinos" style={{ fontSize: "20px" }}>
                    {news.detail.split("@p ").map(function (data, index) {
                      return (
                        <>
                          {data.startsWith("https") ? (
                            <img
                              src={data}
                              alt="newsImage"
                              width="100%"
                              height="400"
                              style={{
                                display: "flex",
                                float: "none",
                                margin: "0 auto",
                                objectFit: "contain",
                                justifyContent: "center",
                              }}
                            />
                          ) : data.startsWith("@t ") ? (
                            data.split("@t ").map(function (data1, index1) {
                              return (
                                <p
                                  key={index1}
                                  style={{
                                    fontSize: "30px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {data1}
                                </p>
                              );
                            })
                          ) : data.startsWith("@l ") ? (
                            <p
                              onClick={() => openInNewTab(data)}
                              style={{
                                textDecorationLine: "underline",
                                color: "blue",
                              }}
                            >
                              Via
                            </p>
                          ) : (
                            <p key={index}>{data}</p>
                          )}
                          <div style={{ height: "10px" }}></div>
                        </>
                      );
                    })}
                  </div>
                </div>
              ))
            )}
          </Col>
          <Col xs={12} sm={12} md={5} lg={4} xl={3}>
            <GoogleAd slot={process.env.REACT_APP_AD_SLOT_PV} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewsDetailPage;
