import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { collection, getDocs, query, where } from "firebase/firestore";
import db from "../Firebase";

const Header = () => {
  const [brandList, setBrandList] = useState([]);
  const [catList, setCatList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getBrandList = async () => {
    const querySnapshot = await getDocs(collection(db, "Brand"));
    const brands = [];
    querySnapshot.forEach((doc) => {
      brands.push(doc.data());
    });
    setBrandList(brands);
    setIsLoading(false);
  };

  const getCatList = async () => {
    const querySnapshot = await getDocs(collection(db, "Categories"));
    const cats = [];
    querySnapshot.forEach((doc) => {
      cats.push(doc.data());
    });
    setCatList(cats);
    setIsLoading(false);
  };

  const getCatData = async (name) => {
    const q1 = query(
      collection(db, "Product"),
      where("categoryName", "==", name)
    );
    const itemsData = [];
    const querySnapshot = await getDocs(q1);
    querySnapshot.forEach((doc) => {
      itemsData.push(doc.data());
      console.log(`${doc.id} => ${doc.data()}`);
    });
    // setProList(itemsData);
    setIsLoading(false);
  };

  const getBrandData = async (name) => {
    const q = query(collection(db, "Product"), where("brandName", "==", name));
    const itemsData1 = [];
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      itemsData1.push(doc.data());
      console.log(`${doc.id} => ${doc.data()}`);
    });
    // setProList(itemsData);
    setIsLoading(false);
  };

  useEffect(() => {
    getBrandList();
    getCatList();
  }, []);

  return (
    <>
      <Container
        className="font-oswald"
        style={{ padding: "0", margin: "0", color: "white" }}
      >
        <Navbar.Brand
          href="/"
          style={{
            fontSize: "35px",
            padding: "0",
            margin: "0",
            height: "45px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
          }}
        >
          DIGIMOBOLO
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav style={{ color: "white" }}>
            <Nav.Link href="/" className="menuText" style={{ color: "white" }}>
              Home
            </Nav.Link>
            <Nav.Link
              href="/latest-news"
              className="menuText"
              style={{ color: "white" }}
            >
              News
            </Nav.Link>
            <NavDropdown
              title={
                <span className="my-auto" style={{ color: "white" }}>
                  Devices
                </span>
              }
              id="collasible-nav-dropdown"
              style={{
                color: "white",
                fontSize: "18px",
                padding: 0,
              }}
            >
              {isLoading === false &&
                catList.map((cat) => (
                  <NavDropdown.Item
                    href={`/filter/${cat.catName}`}
                    key={cat.catId}
                    // onClick={() => getCatData(cat.catName)}
                  >
                    {cat.catName}
                  </NavDropdown.Item>
                ))}
            </NavDropdown>
            <NavDropdown
              title={
                <span className="my-auto" style={{ color: "white" }}>
                  Technology
                </span>
              }
              id="collasible-nav-dropdown"
              style={{
                color: "white",
                fontSize: "18px",
                padding: 0,
              }}
            >
              {isLoading === false &&
                brandList.map((brand) => (
                  <NavDropdown.Item
                    href={`/filter/${brand.brandName}`}
                    key={brand.brandId}
                    onClick={() => getBrandData(brand.brandName)}
                  >
                    {brand.brandName}
                  </NavDropdown.Item>
                ))}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </>
  );
};

export default Header;
