import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import GoogleAd from "../googleads";
import { Img } from "react-image";
import { Link } from "react-router-dom";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import db from "../Firebase";
const NewsPage = () => {
  const [newsList, setNewsList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  const getNewsList = async () => {
    const newsRef = collection(db, "News");
    const q = query(newsRef, orderBy("releasedDate", "desc"));
    const querySnapshot = await getDocs(q);
    const item = [];
    querySnapshot.forEach((doc) => {
      item.push(doc.data());
      console.log(`${doc.id} => ${doc.data()}`);
    });
    setNewsList(item);
    setIsLoading(false);
  };
  useEffect(() => {
    getNewsList();
    console.log(newsList);
  }, []);

  return (
    <div>
      <div style={{ height: "1.5rem" }}></div>
      <Container>
        <Row>
          <Col xs={12} sm={12} md={7} lg={8} xl={9}>
            <div>
              <h4>LATEST NEWS</h4>
              <div style={{ borderBottom: "3px solid black" }}></div>
              <div style={{ height: "1.5rem" }}></div>
              <Container>
                {isLoading ? (
                  <div
                    style={{
                      position: "fixed",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <Spinner animation="border" variant="secondary" />
                  </div>
                ) : (
                  newsList.map((news) => (
                    <Row key={news.newsId}>
                      <Col xs={12} sm={12} md="auto" lg={5} xl={5}>
                        <div
                          style={{
                            width: "100%",
                            height: "auto",
                            // backgroundColor: "grey",
                            borderRadius: "15px",
                            margin: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            float: "none",
                            padding: "0",
                          }}
                        >
                          <Link to={`/news-detail/${news.newsId}`}>
                            <Img
                              src={news.profileImage}
                              alt="newsImage"
                              width="100%"
                              height="auto"
                              style={{
                                objectFit: "contain",
                                borderRadius: "10px",
                              }}
                              loader={
                                <div
                                  style={{
                                    display: "flex",
                                    position: "sticky",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    float: "none",
                                    margin: "0 auto",
                                    width: "100%",
                                    height: "170px",
                                  }}
                                >
                                  <Spinner
                                    animation="border"
                                    variant="secondary"
                                    size="sm"
                                  />
                                </div>
                              }
                            />
                          </Link>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} md="auto" lg={7} xl={7}>
                        <div
                          className="font-oswald"
                          style={{
                            // justifyContent: "flex-end",
                            // alignItems: "flex-end",
                            width: "100%",
                            height: "auto",
                            // position: "relative",
                            paddingTop: "15px",
                          }}
                        >
                          <Link
                            to={`/news-detail/${news.newsId}`}
                            style={{
                              textDecorationLine: "none",
                              color: "black",
                            }}
                          >
                            <h4>{news.title}</h4>
                          </Link>

                          <p>
                            {formatDate(new Date(news.releasedDate.toDate()))}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  ))
                )}
              </Container>
              {/* <div
                style={{
                  width: "100%",
                  height: "200px",
                  //   backgroundColor: "#F5F5F5",
                  display: "flex",
                  alignItems: "flex-start",
                  float: "none",
                  padding: "0",
                  margin: "0 auto",
                }}
              >
                <div
                  style={{
                    width: "40%",
                    height: "180px",
                    // backgroundColor: "grey",
                    borderRadius: "15px",
                    margin: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    float: "none",
                    padding: "0",
                  }}
                >
                  <Img
                    src={oneplus}
                    width="100%"
                    height="170px"
                    style={{
                      objectFit: "contain",
                    }}
                    loader={
                      <div
                        style={{
                          display: "flex",
                          position: "sticky",
                          justifyContent: "center",
                          alignItems: "center",
                          float: "none",
                          margin: "0 auto",
                          width: "60%",
                          height: "250px",
                        }}
                      >
                        <Spinner
                          animation="border"
                          variant="secondary"
                          size="sm"
                        />
                      </div>
                    }
                  />
                </div>
                <div
                  style={{
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    width: "60%",
                    height: "180px",
                    position: "relative",
                    paddingTop: "15px",
                  }}
                >
                  <h3>OnePlus 10 Launch Imminent as It Shows up on TENNA</h3>
                  <h6
                    style={{
                      //   textAlign: "right",
                      //   alignSelf: "flex-end",
                      //   justifyContent: "flex-end",
                      //   alignItems: "flex-end",
                      position: "absolute",
                      bottom: "0",
                      right: "10px",
                      padding: "0",
                    }}
                  >
                    December 30, 2021
                  </h6>
                </div>
              </div> */}
            </div>
          </Col>
          <Col xs={12} sm={12} md={5} lg={4} xl={3}>
            <GoogleAd slot={process.env.REACT_APP_AD_SLOT_HV} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewsPage;
