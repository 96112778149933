import React from "react";
import HomePage from "./screen/homeScreen";
import DetailPage from "./screen/detailScreen";
import NewsPage from "./screen/newsScreen";
import NewsDetailPage from "./screen/newsDetailScreen";
import FilterDataPage from "./screen/filterDataScreen";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import Header from "./component/commonHeader";
import "./index.css";

function App() {
  return (
    <div className="mainContainer">
      <Router>
        <div style={{ height: "1rem" }}></div>
        <Navbar
          collapseOnSelect
          expand="lg"
          bg="dark"
          variant="dark"
          sticky="top"
          style={{ paddingRight: "1rem", paddingLeft: "1rem" }}
        >
          <Header />
        </Navbar>
        <Switch>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/detail/:id" children={<DetailPage />}></Route>
          <Route path="/filter/:name?" children={<FilterDataPage />}></Route>
          <Route path="/latest-news">
            <NewsPage />
          </Route>
          <Route path="/news-detail/:id">
            <NewsDetailPage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
